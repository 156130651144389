// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}


.gform_wrapper {
  ul {
    list-style: none;
    padding: 0;

    li:before {
      display: none;
    }
  }

  .gfield {
    margin-bottom: 15px;

    .gfield_required {
      color: $red;
      margin-left: 5px;
    }

    label {
      font-weight: 800;
      text-transform: uppercase;
    }

    input,
    textarea {
      border: none;
      padding: 7px 10px;
      width: 100%;
      font-size: 16px;
      border: 1px solid $gray;
    }

    textarea {
      height: 180px;
    }
  }

  .gform_footer {

    input[type=submit] {
      -webkit-appearance: none;
      -moz-appearance: none;
      @extend .btn;
      @extend .btn-primary;
    }
  }

  .g-recaptcha label {
    @extend .sr-only;
  }
}

@media only screen and (max-width: 380px) {
  .ginput_recaptcha {
    transform: scale(0.77);
    transform-origin: 0 0;
  }
}
