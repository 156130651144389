.residential-panel, .commercial-industrial-panel {
  padding: 50px 0;

  @include laptop {
    padding: 90px 0 115px;
  }
}


.residential-panel {
  background-image: url('../images/bg_residential.jpg');
  background-position: center;
}


.video-panel {
  background-color: $light-blue;
  border-top: 4px solid $light-gray;
  border-bottom: 4px solid $light-gray;

  @include hd {
    margin: 0 -50px 0 0;
  }

  .video-block {
    position: relative;
    background-color: $light-blue;
    margin: 50px 0 0;

    @include laptop {
      margin: 50px 0;
    }

    @include wide {
      min-height: calc(100% + 50px);
      margin: -25px 0;
      padding: 13px;
      border: 4px solid $light-gray;
    }

    &:before, &:after {
      display: none;
      content: '';
      background-color: $light-blue;
      height: calc(100% - 42px);
      position: absolute;
      top: 21px;
      width: 6px;

      @include wide {
        display: block;
      }
    }

    &:before {
      left: -5px;
    }

    &:after {
      right: -5px;
    }

    .video-container {

      @include wide {
        margin: 10px 0 0;
      }

      @include hd {
        margin: 0;
      }
    }
  }

  .content {
    padding: 25px 0 50px;
    text-align: center;

    @include tablet {
      text-align: left;
    }

    @include laptop {
      padding: 50px 0;
      margin: 0 -45px 0 15px;
    }

    h1, p {
      color: $white;
    }

    p {
      margin: 0 0 20px;
    }
  }
}


.commercial-industrial-panel {
  background-image: url('../images/bg_commercial-industrial.jpg');
  background-position: bottom;
}


.triple-feature {
  background-color: $light-gray;

  .feature-blocks {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .feature {
      @include flex(0,0, 100%);
      padding: 20px;
      margin: 0 0 30px;

      @include tablet {
        @include flex(0,0, 100%);
        padding: 20px 25px;
      }

      @include laptop {
        @include flex(0,0, 32%);
        margin: 0;
      }

      @include hd {
        @include flex(0,0, 375px);
      }

      &:last-of-type {
        margin: 0;
      }

      .feature-image {
        width: 100%;
        margin: 0 0 25px;
      }
    }
  }
}
