.header-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;

  .banner {
    padding: 7px 0 10px;
    background-color: rgba($white, .9);

    .logo {
      width: 230px;

      @include desktop {
        width: 302px;
      }
    }
  }

  .navigation {

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        text-align: center;

        a {
          font-family: $secondary-font;
          font-size: 13px;
          font-weight: 600;
          text-transform: uppercase;

          @include hd {
            font-size: 16px;
          }
        }

      }
    }

    &.nav-top {
      display: none;

      @include desktop {
        display: block;
        margin: 35px 0 0;
      }

      @include wide {
        margin: 45px 0 0;
      }

      ul {
        text-align: right;

        li {
          margin-left: 25px;

          &:first-of-type {
            margin: 0;
          }

          a {
            color: $black;
          }

          a:hover, &.current-menu-item a {
            color: $blue;
          }

          &.nav-btn a {
            background-color: $blue;
            color: $white;
            padding: 10px 25px;

            &:hover {
              background-color: darken($blue, 15);
            }

            .fa {
              margin-right: 5px;
            }
          }

          &.phone a {
            color: $black;
            cursor: default;
          }
        }
      }
    }

    &.nav-primary {
      background-color: $dark-green;
      min-height: 10px;

      ul {
        justify-content: space-between;
        background-color: $dark-green;
        display: none;

        @include desktop {
          display: flex;
        }

        li {
          position: relative;
          flex-grow: 1;

          a {
            color: $white;
            display: block;
            padding: 13px 10px;

            @include hd {
              padding: 15px 20px;
            }
          }

          a:hover, &.current-menu-item a {
            background-color: darken($dark-green, 5);
          }
        }
      }
    }
  }
}


.hero {
  padding: 160px 0 240px;
  position: relative;
  background-color: $light-gray;
  background-image: url('../images/hero_home.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 15px;

  @include laptop {
    padding: 200px 0 280px;
  }

  @include desktop {
    min-height: 960px;
    padding: 350px 0;
  }

  &:before {
    content: '';
    @include stretch;
    background-color: rgba($black, .4);

    @include laptop {
      background-color: rgba($black, .35);
    }
  }

  .cta {
    text-align: center;

    @include laptop {
      text-align: left;
    }

    p {
      font-family: $tertiary-font;
      color: $white;
    }

    .sub-heading {
      font-size: 20px;
      line-height: 24px;
      margin: 0 0 30px;
    }

    .heading {
      font-size: 32px;
      line-height: 38px;
      font-weight: 600;
      margin: 0 0 15px;

      @include laptop {
        font-size: 46px;
        line-height: 54px;
      }
    }

    .btn {
      font-family: $tertiary-font;
      font-size: 18px;
      min-width: 230px;
      margin: 10px 0 15px;

      @include tablet {
        margin: 5px 15px;
      }

      @include laptop {
        margin: 0 15px 0 0;
        font-size: 20px;
      }
    }
  }

  .strapline {
    padding: 30px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba($dark-gray, .7);


    @include laptop {
      padding: 55px 0;
    }

    p {
      font-family: $tertiary-font;
      font-size: 24px;
      line-height: 28px;
      color: $white;
      margin: 0;
      text-align: center;

      @include tablet {
        font-size: 28px;
        line-height: 30px;
      }

      @include laptop {
        white-space: nowrap;
        display: inline-block;
        text-align: left;
      }

      @include desktop {
        font-size: 32.5px;
        line-height: 32px;
      }
    }

    .btn {
      font-size: 17.7px;
      margin: 20px auto 0;
      display: block;
      max-width: 254px;

      @include laptop {
        margin: -10px 0 0 10px;
        display: inline-block;
      }
    }
  }

  &.interior-hero {
    padding: 140px 0 50px 0;
    min-height: auto;
    background-position: center;

    @include desktop {
      padding: 270px 0 160px;
    }

    &:before {
      background-color: rgba($black, .5);

      @include laptop {
        background-color: rgba($black, .45);
      }
    }
  }

  &.no-hero {
    height: 98px;
    padding: 0;
    background-image: none;
    background: $light-gray;
    margin: 0;

    @include desktop {
      height: 142px;
    }

    @include hd {
      height: 157px;
    }
  }
}
