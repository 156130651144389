// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

$brand-primary:         #778f0f;
$light-blue:            #4a95c9;
$light-green:           #bfe900;
$medium-green:          #7ca624;
$dark-green:            #778a00;
$blue:                  #4474ab;
$white:                 #ffffff;
$black:                 #000000;
$gray:                  #ececec;
$light-gray:            #f9f9f9;
$dark-gray:             #323232;
$gray-100:              #f8f9fa;
$gray-600:              #868e96;
$gray-800:              #343a40;
$red:                   #dc3545;
$yellow:                #ffc107;
$green:                 #28a745;
$cyan:                  #17a2b8;

$theme-colors: (
  primary:              $brand-primary,
  secondary:            $gray-600,
  success:              $green,
  info:                 $cyan,
  warning:              $yellow,
  danger:               $red,
  light:                $gray-100,
  dark:                 $gray-800
);


// Fonts
$primary-font:          'Open Sans', sans-serif; // 300, 400
$secondary-font:        'Poppins', sans-serif; // 300, 500, 600, 700
$tertiary-font:         'Oswald', sans-serif; // 400, 600
$body-font-size:        15px;
$body-font-weight:      400;
$body-font:             $primary-font;
$body-color:            $black;
$heading-font:          $secondary-font;
$heading-color:         $black;
$h1-weight:             600;
$h1-size:               24px;
$h2-weight:             600;
$h2-size:               20px;
$h3-weight:             600;
$h3-size:               18px;

// Base Styles
$base-transition:       0.3s ease-in-out;
$section-padding:       60px;


//Side-Nav
$sideNavWidth: 260px;
$sideNavDirection: (right);

$button_width: 40px;     // width of button area
$button_height: 60px;     // height of button area
$bar_thickness: 5px;     // thickness of button bars
$bar_roundness: 0;
$button_pad: 0px;      // left/right padding between button area and bars.
$button_bar_space: 8px;     // spacing between button bars
$button_transistion_duration: 0.3s;  // transition duration
$hamburger_normal: $dark-green;
$hamburger_active: $dark-green;

// Bootstrap Variables
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  wd: 1440px,
  hd: 1600px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px,
  wd: 1400px,
  hd: 1560px
);


// Breakpoint variables
$width_mobile:          480px;
$width_phablet:         576px;
$width_tablet:          768px;
$width_laptop:          992px;
$width_desktop:         1200px;
$width_wide:            1440px;
$width_hd:              1600px;

/*
 * Mixins
 */
// Media query mixins
@mixin mobile { @media (min-width: #{$width_mobile}) { @content; } }
@mixin phablet { @media (min-width: #{$width_phablet}) { @content; } }
@mixin tablet { @media (min-width: #{$width_tablet}) { @content; } }
@mixin laptop { @media (min-width: #{$width_laptop}) { @content; } }
@mixin desktop { @media (min-width: #{$width_desktop}) { @content; } }
@mixin wide { @media (min-width: #{$width_wide}) { @content; } }
@mixin hd { @media (min-width: #{$width_hd}) { @content; } }


@mixin stretch($offset: 0) {
  position: absolute;
  top: #{$offset};
  right: #{$offset};
  left: #{$offset};
  bottom: #{$offset};
}


@mixin smooth-fonts() {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@mixin letter-spacing($letter-spacing: 0) {
  letter-spacing: calc(#{$letter-spacing} * 0.001em);
}


// Flexbox mixin - prevents most IE 11 issues
 @mixin flex($grow: 0, $shrink: 0, $basis: 100%) {
   flex: #{$grow} #{$shrink} #{$basis};
   max-width: #{$basis};
 }
