.btn {
  border-radius: 0;
  padding: 10px 20px;
  font-family: $secondary-font;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  transition: $base-transition;
  min-width: 254px;

  @include tablet {
    font-size: 18px;
    padding: 10px 30px;
  }

  .fa {
    margin-right: 5px;
  }

  &.blue {
    background-color: $blue;
    color: $white;

    &:hover {
      background-color: darken($blue, 15);
    }
  }

  &.light-blue {
    background-color: $light-blue;
    color: $white;

    &:hover {
      background-color: darken($blue, 15);
    }
  }

  &.white {
    background-color: $white;
    color: $light-blue;

    &:hover {
      background-color: $blue;
      color: $white;
    }
  }
}
