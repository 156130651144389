.inner-content {
  padding: $section-padding 0;

  p {
    margin: 0 0 20px;
    display: block;
  }

  ul {
    margin: 0 0 25px;
  }

  h1 {
    font-size: 20px;
    margin: 0 0 30px;

    @include tablet {
      font-size: 26px;
    }
  }

  h2 {
    margin: 0 0 15px;
    font-size: 18px;

    @include tablet {
      font-size: 22px;
    }
  }

  h3 {
    margin: 0 0 15px;
    font-size: 17px;

    @include tablet {
      font-size: 20px;
    }
  }

  h4 {
    font-size: 16px;
    text-transform: uppercase;
    margin: 0 0 20px;
    font-weight: 400;

    @include tablet {
      font-size: 18px;
    }
  }

  .card {
    margin: 0 0 30px;
  }

  .btn {
    margin: 5px 0 10px;
  }

  table {
    width: 100%;
    max-width: 100%;
    border: 1px solid $gray;
    margin: 0 0 20px;

    th, td {
      padding: 7px;
      font-size: 13px;

      @include phablet {
        padding: 7px 15px;
        font-size: $body-font-size;
      }
    }

    th {
      background-color: $white;
    }

    tr {

      &:nth-child(even) {
        background-color: $white;
      }

      &:nth-child(odd) {
        background-color: lighten($gray, 3%);
      }
    }
  }

  .alignleft {
    width: 100%;
    margin: 0 0 15px;

    @include tablet {
      width: 300px;
      margin: 0 20px 5px 0;
    }
  }
}

.flex-list-thirds {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  li {
    @include flex(0,0,33.3%);

    @include phablet {
      @include flex(0,0,50%);
    }

    @include laptop {
      @include flex(0,0,33.3%);
    }
  }
}


.breadcrumbs {
  display: block;
  padding: 11px 10px;
  margin: 0 0 30px;
  border: 1px solid $gray;
  background-color: $white;
  font-size: 13px;

  @include phablet {
    font-size: 14px;
    margin: 0 0 40px;
  }

  span[property="itemListElement"] {
    margin: 0 3px;

    @include phablet {
      margin: 0 5px;
    }
  }
}


.accordion {
  margin: 0 0 25px;

  .card {
    margin: -1px 0 0;
    padding: 0;

    .panel-heading {

      h5 {
        margin: 0;
        font-weight: 400;
      }

      a {
        position: relative;
        display: block;
        padding: 13px 30px 13px 15px;

        &:after {
          font-family: FontAwesome;
          content: '\f068';
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }

        &.collapsed:after {
          content: '\f067';
        }
      }
    }

    .panel-body {
      padding: 13px 15px;
      border-top: 1px solid $gray;
    }
  }
}

.contractor-list {

  .contractor {
    margin: 0 0 30px;

    .card {
      margin: 0;
      padding: 15px;
      height: 100%;
    }

    .img-container {
      min-height: 145px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .contractor-logo, p {
      margin: 0 0 10px;
    }

    .contractor-name {
      font-weight: 700;
    }
  }
}

table.fossil-fuel-emisson-levels {
  width: 400;
  border: 0;

  tr:not(:first-of-type), tr:not(:last-of-type) {
    td {
      background-color: #ccc;
    }
  
    td:nth-of-type(1) {
      width: 142px;
    }
  
    td:nth-of-type(2) {
      width: 92px;
    }
  
    td:nth-of-type(3) {
      width: 77px;
    }
  
    td:nth-of-type(4) {
      width: 68px;
    }
  }

  tr:first-of-type td, tr:last-of-type td {
    background-color: #f4f4f4;
  }
}

.two-col {
  @include tablet {
    column-count: 2;
    column-gap: 55px;
  }

  .col-content {
    @include wide {
      break-inside: avoid;
    }
  }

  .alignleft {
    @include tablet {
      width: 200px;
    }
  }
}