.inner-sidebar {
  margin: 30px 0 0;

  @include laptop {
    margin: 0;
  }

  .card {
    margin: 0 0 30px;

    ul {
      margin: 0;
    }
  }
}
