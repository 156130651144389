.service-area-map {
  display: block;

  .embed-container {
    display: block;
    position: relative;
    max-width: 100%;
  }
  .embed-container iframe, .embed-container object, .embed-container iframe {
    width: 100%;
    height: 300px;

    @include tablet {
      height: 500px;
    }

    @include wide {
      height: 700px;
    }
  }

  small {
    position: absolute; z-index: 40; bottom: 0; margin-bottom: -15px;
  }
}


.footer-service-area {
  padding: 50px 0;
  background-image: url('../images/bg_map.jpg');
  background-size: cover;
  background-position: center 10%;
  background-repeat: no-repeat;

  @include laptop {
    padding: 105px 0;
  }

  .content-card {
    position: relative;
    padding: 30px 15px;
    z-index: 1;

    @include tablet {
      padding: 30px;
    }

    .service-area-search {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;

      input, button {
        height: 50px;
        border: 1px solid #b2b2b2;
      }

      .address {
        @include flex(0,0,auto);
        flex-grow: 1;
        border-right: 0px;
        padding: 10px 20px;
      }

      .submit {
        @include flex(0,0,50px);
        background-color: $medium-green;
        color: $white;
        border-left: 0px;
        font-size: 24px;
        cursor: pointer;
        transition: $base-transition;

        &:hover {
          background-color: darken($medium-green, 10);
        }
      }
    }

    .card-arrow {
      display: none;
      position: absolute;
      right: -133px;
      bottom: 25px;
      z-index: -1;

      @include laptop {
        display: block;
      }
    }
  }
}


.footer-cta {
  padding: 50px 0;
  background-image: url('../images/bg_footer-cta.jpg');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  margin-top: 15px;

  @include laptop {
    padding: 195px 0 125px;
  }

  .cta {
    background-color: rgba($black, .55);
    text-align: center;
    padding: 25px;

    @include laptop {
      margin: 0 -30px;
      background-color: rgba($black, .4);
    }

    p {
      font-family: $tertiary-font;
      color: $white;
      text-transform: uppercase;
    }

    .heading {
      font-size: 28px;
      line-height: 30px;
      font-weight: 600;
      margin: 0 0 15px;

      @include tablet {
        font-size: 30px;
        margin: 0 0 20px;
      }

      @include laptop {
        font-size: 40px;
        line-height: 40px;
      }

      @include wide {
        font-size: 48px;
        line-height: 48px;
      }
    }

    .sub-heading {
      font-family: $secondary-font;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      margin: 0 0 20px;

      @include tablet {
        font-size: 18px;
        line-height: 18px;
        margin: 0 0 30px;
      }
    }

    .btn {
      font-family: $tertiary-font;
      font-size: 18px;

      @include laptop {
        font-size: 24px;
      }
    }
  }
}


.footer {
  padding: 20px 0 35px;
  background-color: $white;
  text-align: center;

  .logo {
    margin: 0 0 25px;
  }

  a, p {
    font-family: $primary-font;
    color: $black;
    font-size: 15px;
    font-weight: 300;
  }

  p {
    margin: 0 0 5px;
  }

  .footer-nav {

    .nav-menu {
      list-style-type: none;
      margin: 25px 0 0;
      padding: 0;

      li {
        @include tablet {
          display: inline-block;
        }

        a {
          display: block;
          padding: 8px 0;

          @include tablet {
            padding: 3px 10px;
          }

          @include laptop {
            padding: 3px 12px;
          }

          &:hover {
            color: $brand-primary;
          }
        }
      }
    }
  }
}


.linksCopy {
  padding: 10px 0;
  background-color: $light-gray;

  p {
    margin: 0;
    font-size: 10px;
    text-align: center;

    @include phablet {
      font-size: 12px;
    }

    @include desktop {
      text-align: left;
    }
  }

  .right-text {
    text-align: center;

    @include desktop {
      text-align: right;
    }
  }

  a {
    color: $black;
  }
}
