html, body {
  font-size: $body-font-size;
  font-weight: $body-font-weight;
  font-family: $body-font;
  color: $body-color;
  @include smooth-fonts;
  background-color: $light-gray;
}

img {
  max-width: 100%;
  height: auto;
}

h1, h2, h3, h4 {
  text-transform: uppercase;
}

h1, .h1 {
  color: $heading-color;
  font-family: $heading-font;
  font-weight: $h1-weight;
  font-size: 18px;

  @include tablet {
    font-size: 20px;
  }

  @include wide {
    font-size: $h1-size;
  }
}

h2, .h2 {
  color: $heading-color;
  font-family: $heading-font;
  font-weight: $h2-weight;
  font-size: $h2-size;
}

h3, .h3 {
  color: $heading-color;
  font-family: $heading-font;
  font-weight: $h3-weight;
  font-size: $h3-size;
}

h4, .h4 {
  color: $heading-color;
  font-family: $heading-font;
}

h5, .h5 {
  color: $heading-color;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
}

p, li {
  font-family: $body-font;
  font-size: $body-font-size;
  font-weight: $body-font-weight;
  line-height: 24px;
}

a {
  transition: $base-transition;
  color: $brand-primary;

  &:hover, &:focus {
    text-decoration: none;
  }
}

// Global content styles
.panel, .inner-content {
  ul {
    margin: 0 0 10px;
    padding: 0 0 0 15px;
    list-style-type: none;

    @include desktop {
      padding: 0 0 0 25px;
    }

    li {
      padding: 0 0;
      position: relative;

      &:before {
        font-family: FontAwesome;
        font-size: 16px;
        content: '\f0da';
        color: $black;
        margin-right: 8px;
        transition: $base-transition;
      }

      a {
        color: $black;
        border-bottom: 1px solid $black;

        &:hover {
          color: $brand-primary;
          border-color: $brand-primary;
        }
      }
    }
  }
}

// Homepage and footer panel
.panel {
  padding: ($section-padding - 20px) 0;
  background-size: cover;
  background-repeat: no-repeat;

  @include tablet {
    padding: ($section-padding - 10px) 0;
  }

  @include laptop {
    padding: $section-padding 0;
  }

  h1 {
    text-align: center;
    position: relative;
    margin: 0 0 30px;

    &:before, &:after {
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    &:before {
      content: '';
      width: 190px;
      height: 2px;
      background-color: $medium-green;
      bottom: -14px;

      @include laptop {
        width: 245px;
      }
    }

    &:after {
      font-family: FontAwesome;
      content: '\f111';
      font-size: 8px;
      color: $medium-green;
      bottom: -17px;
      background-color: $white;
      padding: 0 5px;
    }
  }

  p {
    margin: 0 0 20px;
  }

  .content-card {
    text-align: center;
  }

}


.green-text {
  color: $light-green;

  &.dark {
    color: $medium-green;
  }
}

// Card styles
.card, .content-card {
  display: block;
  border-radius: 0;
  background-color: $white;

  p:last-child {
    margin: 0;
  }
}

.content-card {
  padding: 30px 15px;
  position: relative;
  border: 3px solid $medium-green;

  @include tablet {
    padding: 30px 50px;
    box-shadow: 10px 10px 0px -2px $medium-green;
  }
}

.card {
  border: 1px solid $gray;
  padding: 30px;

  &.bordered {
    border-bottom: 3px solid $medium-green;
  }
}

// Responsive video wrapper
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;

  iframe, object, embed, video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
